import axios from "axios";
import router from "../../Router/index";

const state = {
  currentLoggedUser: null,
  newUser: {
    firstName: "",
    lastName: "",
    location: "",
    phoneNumber: "",
    userName: "",
    email: "",
    password: "",
  },
  newProduct: {
    category: null,
    categoryName: "",
    subcategory: null,
    subcategoryName: "",
    name: "",
    price: "",
    otherPaymentOption: 0,
    isPriceFixed: false,
    isExchangePossible: false,
    description: "",
    useMyLocation: true,
    location: "",
    useMyPhoneNum: true,
    phoneNumber: "",
    mainImage: null,
    mainImageUrl: null,
    otherImages: [],
    otherImagesUrl: [],
  },
};

const getters = {
  isAuthenticated: (state) => !!state.currentLoggedUser,
  StateCurrentUser: (state) => state.currentLoggedUser,
  StateNewUser: (state) => state.newUser,
  StateNewProduct: (state) => state.newProduct,
};

const actions = {
  async LogIn({ commit }, user) {
    const tempUser = await axios.post("Login", user);
    localStorage.setItem("jwtToken", tempUser.data.token);
    await commit("setUser", tempUser.data);
  },
  async LogOut({ commit }) {
    localStorage.removeItem("jwtToken");
    let user = null;
    await commit("deleteProduct");
    await commit("logout", user);
    await commit("redirect");
  },
  async AddNewUserInfo({ commit }, data) {
    await commit("addNewUserInfo", data);
  },
  async RemoveNewUserInfo({ commit }) {
    await commit("removeNewUserInfo");
  },
  async AddProductCategory({ commit }, currentCategory) {
    await commit("addProductCategory", currentCategory);
  },
  async AddProductInfo({ commit }, currentInfo) {
    await commit("addProductInfo", currentInfo);
  },
  async AddProductImages({ commit }, currentImages) {
    await commit("addProductImages", currentImages);
  },
  async RemoveProduct({ commit }) {
    await commit("deleteProduct");
  },
};

const mutations = {
  setUser(state, temp) {
    state.currentLoggedUser = temp;
  },
  addNewUserInfo(state, data) {
    state.newUser.firstName = data.name;
    state.newUser.lastName = data.lastName;
    state.newUser.location = data.location;
    state.newUser.phoneNumber = data.phoneNumber;
    state.newUser.userName = data.userName;
    state.newUser.email = data.email;
    state.newUser.password = data.password;
  },
  removeNewUserInfo(state) {
    let initialState = {
      firstName: "",
      lastName: "",
      location: "",
      phoneNumber: "",
      userName: "",
      email: "",
      password: "",
    };
    state.newUser = initialState;
  },
  logout(state, user) {
    state.currentLoggedUser = user;
  },
  redirect() {
    router.push("/pocetna");
  },
  addProductCategory(state, currentCategory) {
    state.newProduct.category = currentCategory.category;
    state.newProduct.categoryName = currentCategory.categoryName;
    state.newProduct.subcategory = currentCategory.subcategory;
    state.newProduct.subcategoryName = currentCategory.subcategoryName;
  },
  addProductInfo(state, currentInfo) {
    state.newProduct.name = currentInfo.name;
    state.newProduct.price = currentInfo.price;
    state.newProduct.otherPaymentOption = currentInfo.otherPaymentOption;
    state.newProduct.isPriceFixed = currentInfo.isPriceFixed;
    state.newProduct.isExchangePossible = currentInfo.isExchangePossible;
    state.newProduct.description = currentInfo.description;
    state.newProduct.useMyLocation = currentInfo.useMyLocation;
    state.newProduct.location = currentInfo.location;
    state.newProduct.useMyPhoneNum = currentInfo.useMyPhoneNum;
    state.newProduct.phoneNumber = currentInfo.phoneNumber;
  },
  addProductImages(state, currentImages) {
    state.newProduct.mainImage = currentImages.mainImage;
    state.newProduct.mainImageUrl = currentImages.mainImageUrl;
    state.newProduct.otherImages = currentImages.otherImages;
    state.newProduct.otherImagesUrl = currentImages.otherImagesUrl;
  },
  deleteProduct(state) {
    let nullObject = {
      category: null,
      categoryName: "",
      subcategory: null,
      subcategoryName: "",
      name: "",
      price: "",
      otherPaymentOption: 0,
      isPriceFixed: false,
      isExchangePossible: false,
      description: "",
      useMyLocation: true,
      location: "",
      useMyPhoneNum: true,
      phoneNumber: "",
      mainImage: null,
      mainImageUrl: null,
      otherImages: [],
      otherImagesUrl: [],
    };
    state.newProduct = nullObject;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
