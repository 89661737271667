import Vue from "vue";
import App from "./App.vue";
import vuetify from "./Plugins/vuetify";
import router from "./Router";
import store from "./Store";
import axios from "axios";

Vue.config.productionTip = false;
Vue.prototype.$http = axios;

axios.defaults.baseURL = "https://api.poljopijaca.rs/api/";

axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("jwtToken");

    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },

  (error) => {
    return Promise.reject(error);
  }
);

new Vue({
  vuetify,
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
