<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",
  components: {},
  data() {
    return {};
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400;500;800;900&family=Roboto&display=swap");

* {
  font-family: "Roboto", sans-serif;
}
.v-main__wrap {
  background-color: #ececef;
}
.v-image__image--cover {
  background-size: contain !important;
}
button.v-pagination__item.v-pagination__item--active.primary {
  background-color: #137547 !important;
}
</style>
