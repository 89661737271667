import Vue from "vue";
import VueRouter from "vue-router";
import store from "../Store";

Vue.use(VueRouter);

const routerOptions = [
  { path: "/", component: "Pages/HomePage", meta: { guest: true } },
  { path: "/pocetna", component: "Pages/HomePage", meta: { guest: true } },
  { path: "/pretraga-oglasa", component: "Pages/SearchPage", meta: { guest: true } },
  { path: "/pretraga-oglasa/:subcategory", component: "Pages/SearchPage", meta: { guest: true } },
  // { path: "/poruke", component: "Pages/Messages", meta: { guest: true } },
  { path: "/dodaj-oglas", component: "Pages/AddProduct/Main" },
  { path: "/prijava", component: "Pages/SignInPage", meta: { guest: true } },
  { path: "/registracija", component: "Pages/SignUp/Main", meta: { guest: true } },
  { path: "/zaboravljena-lozinka", component: "Pages/ForgottenPassword" },
  { path: "/promeni-lozinku/:hash", component: "Pages/ChangePassword" },
  { path: "/oglas/:hash", component: "Pages/SingleItemView/Main" },
  { path: "/moj-nalog", component: "Pages/UserProfile/Main" },
  { path: "/admin/kategorije", component: "Pages/AdminPanel/CategoriesPage" },
  { path: "/admin/korisnici", component: "Pages/AdminPanel/Users/Main", meta: { requiresAuth: true, is_admin: true } },
  { path: "/admin/oglasi", component: "Pages/AdminPanel/Products/Main", meta: { requiresAuth: true, is_admin: true } },
  { path: "/admin/prijavljeni-oglasi", component: "Pages/AdminPanel/Products/InappropriateProducts", meta: { requiresAuth: true, is_admin: true } },
];

const routes = routerOptions.map((route) => {
  return {
    ...route,
    component: () => import(`../${route.component}.vue`),
  };
});

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.fullPath === "/dodaj-oglas") {
    if (!store.getters.isAuthenticated) {
      next("/prijava");
    }
  }
  if (to.fullPath === "/prijava") {
    if (store.getters.isAuthenticated) {
      next("/pocetna");
    }
  }
  if (to.fullPath === "/registracija") {
    if (store.getters.isAuthenticated) {
      next("/pocetna");
    }
  }
  if (to.fullPath === "/moj-nalog") {
    if (!store.getters.isAuthenticated) {
      next("/pocetna");
    }
  }
  if (to.fullPath === "/prikaz-oglasa") {
    if (!store.getters.isAuthenticated) {
      next("/pocetna");
    }
  }
  next();
});

export default router;
